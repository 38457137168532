<template>
  <div class="w-full">
    <Loader v-show="!partnerData" class="w-full h-sidebar rounded-lg" no-border/>
    <div class="w-full gap-y-6 gap-x-6 bg-white rounded-lg">
      <div class="w-full flex flex-col lg:flex-row px-3 pt-3 lg:px-6 lg:pt-6 gap-6">
        <!-- DESKTOP -->
        <div class="hidden lg:block flex lg:w-1/2 h-post-img max-h-post-img">
          <div class="flex flex-col h-post-img content-end">
            <!-- Nazwa partnera -->
            <div class="text-black font-semibold leading-tight text-lg">
              {{ partnerData.name }}
            </div>
            <!-- SEPARTOR LINE -->
            <hr class="my-3 border-grayedDark border-dashed bg-white"/>
            <div class="flex flex-row">
              <!-- Spolecznosciowki -->
              <div class="w-full flex flex-row gap-4 justify-end items-center">
                <PartnerSocialIcon :url="partnerData.www_url" icon-name="www" tooltip="WWW"/>
                <PartnerSocialIcon :url="partnerData.facebook_url" icon-name="facebook" tooltip="Facebook"/>
                <PartnerSocialIcon :url="partnerData.instagram_url" icon-name="instagram" tooltip="Instagram"/>
                <PartnerSocialIcon :url="partnerData.tiktok_url" icon-name="tiktok" tooltip="TikTok"/>
                <PartnerSocialIcon :url="partnerData.youtube_url" icon-name="youtube" tooltip="Youtube"/>
                <PartnerSocialIcon :url="partnerData.linkedin_url" icon-name="linkedin" tooltip="Linkedin"/>
              </div>
            </div>
            <div class="my-auto"></div>
            <!-- SUMMARY -->
            <div class="font-semibold leading-snug text-base text-black mt-3">
              <span v-html="partnerData.summary"/>
            </div>
          </div>
        </div>
        <!-- Obrazek -->
        <div class="w-full lg:w-1/2 h-post-img max-h-post-img rounded-lg border border-grayedDark"
          :class="[getHeightOfScreenWidth(0.75)]"
          :style="[{background: 'url(' + partnerData.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]"
        />
        <!-- MOBILE -->
        <div class="lg:hidden flex flex-col w-full">
          <div class="flex flex-col">
            <!-- TITLE -->
            <div class="text-black font-semibold leading-tight text-lg">
              {{ partnerData.name }}
            </div>
            <!-- SEPARTOR LINE -->
            <hr class="my-3 border-grayedDark border-dashed bg-white"/>
            <div class="flex flex-row">
              <!-- Spolecznosciowki -->
              <div class="w-full flex flex-row gap-4 justify-end items-center">
                <PartnerSocialIcon :url="partnerData.www_url" icon-name="www" tooltip="WWW"/>
                <PartnerSocialIcon :url="partnerData.facebook_url" icon-name="facebook" tooltip="Facebook"/>
                <PartnerSocialIcon :url="partnerData.instagram_url" icon-name="instagram" tooltip="Instagram"/>
                <PartnerSocialIcon :url="partnerData.tiktok_url" icon-name="tiktok" tooltip="TikTok"/>
                <PartnerSocialIcon :url="partnerData.youtube_url" icon-name="youtube" tooltip="Youtube"/>
                <PartnerSocialIcon :url="partnerData.linkedin_url" icon-name="linkedin" tooltip="Linkedin"/>
              </div>
            </div>
            <!-- EXCERPT -->
            <div class="font-semibold leading-snug text-base text-black mt-3 lg:mt-6">
              <span v-html="partnerData.summary"/>
            </div>
          </div>
        </div>
      </div>
      <!-- Opis -->
      <div v-if="partnerData" class="bg-white text-base px-3 lg:px-6 pb-3 lg:pb-6 mb-1.5 rounded-b-md space-y-4">
        <hr class="border-transparent"/>
        <div class="text-base ql-content">
          <span class="post-body" v-html="partnerData.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "@/views/content/filters/Filters";
import Modal from "@/utils/modal/Modal";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import GalleryProfile from "@/views/content/profile/components/gallery/GalleryShow";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Popover from "@/utils/popover/Popover";
import BaseIcon from "@/utils/icons/BaseIcon";
import Loader from "@/views/Loader.vue";
import {getHeightOfScreenWidth, goToSpecificRoute} from "@/common/helpers/utils";
import Hint from "@/views/content/components/Hint";
import ContactForm from "@/views/content/components/ContactForm.vue";
import PartnerSocialIcon from "@/views/content/partners/components/PartnerSocialIcon.vue"

export default {
  name: "PartnerShow",
  components: {
    ContactForm,
    Badge,
    BadgeFrame,
    BaseIcon,
    Button,
    Filters,
    GalleryProfile,
    Hint,
    Loader,
    Modal,
    PageContainer,
    PageTitle,
    Popover,
    PartnerSocialIcon
  },
  props: {
    slug: String,
    partnerData: Object,
  },
  data() {
    return {
      getHeightOfScreenWidth: getHeightOfScreenWidth,
    }
  },
  computed: {
    isMobile() {
      return checkIsMobileDevice();
    }
  },
  watch: {},
  methods: {},
  mounted() {
  },


}
</script>

<style lang="scss">

.h-post-screen {
  height: 78vh;
}
@media (min-width: 1024px) {
  .h-post-screen {
    height: 88vh;
  }
}


</style>
